#walkme-player {
  display: none !important;
}

#walkme-footer {
  display: none !important;
}

#walkme-menu #walkme-title {
  margin: 20px 54px 0 20px !important;
  color: var(--text-over-light-high) !important;
  font-family: 'Work Sans', serif !important;
  font-weight: 600 !important;
  font-size: 24px !important;
}

#walkme-menu #walkme-title::before {
  background: none !important;
}

#walkme-menu.walkme-menu.walkme-menu.walkme-penguin.walkme-california.walkme-theme-white-blue .walkme-deployable {
  /*. icons */

  & .walkme-activatable .walkme-deployable-icon {
    color: var(--navy-500) !important;
  }

  /* tab */

  &.walkme-category.walkme-category > .walkme-deployable-row {
    background-color: #fff !important;

    &:hover {
      background: var(--sapphire-50) !important;
    }

    /* tab selected */

    &[aria-expanded='true'] {
      background-color: var(--surface-100) !important;
    }

    & .walkme-deployable-icon {
      font-size: 8px;
    }
  }
}

/* menu items */
#walkme-menu #walkme-main #walkme-tabs-wrapper .walkme-search-results-list .walkme-content .walkme-deployable-row,
#walkme-menu
  #walkme-main
  #walkme-tabs-wrapper
  .walkme-deployables-list
  .walkme-tab
  .walkme-content
  .walkme-deployable-row,
#walkme-menu #walkme-main #walkme-tabs-wrapper .walkme-shuttle .walkme-deployable-row {
  border-radius: 0 !important;

  &:hover {
    background: var(--sapphire-50) !important;
  }

  & .walkme-deployable-icon {
    color: var(--navy-500) !important;
  }

  & .walkme-text .walkme-deployable-name {
    color: var(--text-over-light-high) !important;
    padding: 2px 0 2px 0 !important;
  }

  & .walkme-description {
    color: var(--text-over-light-medium) !important;
  }
}

/* category dropdown */
#walkme-menu #walkme-main #walkme-tabs-wrapper .walkme-deployables-list .walkme-tab .walkme-category {
  width: 100% !important;

  & .walkme-deployable-row {
    border-radius: 8px !important;
  }

  & .walkme-content .walkme-deployable-row {
    border-radius: 0 !important;
  }

  & .walkme-deployable-icon {
    color: var(--sapphire-500) !important;
    background: transparent !important;
    width: 24px !important;
    height: 24px !important;
    margin-top: 4px !important;
  }

  & .walkme-text .walkme-deployable-name {
    color: var(--text-over-light-high) !important;
    padding: 2px 0 2px 0 !important;
  }
}

/* search input */
#walkme-menu
  #walkme-main
  #walkme-tabs-wrapper
  .walkme-search-box-container
  .walkme-search-box-button
  .walkme-search-box-button-image {
  color: var(--text-over-light-medium) !important;
}

#walkme-menu #walkme-main #walkme-tabs-wrapper .walkme-search-box-container .walkme-search-box:hover {
  border-color: var(--navy-500) !important;
  color: var(--text-over-light-high) !important;
}

/* search results */

#walkme-menu #walkme-main #walkme-tabs-wrapper .walkme-search-results-list-inner {
  margin: 0 6px 0 6px !important;
}

/* size of the whole menu */
#walkme-menu .walkme-deployables-list {
  height: 87% !important;
  padding-bottom: 20px !important;
  max-height: 575px !important;

  & .walkme-tab {
    margin: 0 8px 0 8px !important;
  }

  & .walkme-jspContainer {
    height: 100% !important;
  }
}

#walkme-menu .walkme-search-results-list {
  height: 87% !important;

  & .walkme-jspContainer {
    height: 500px !important;
  }
}

/* animations when the menu appears */
#walkme-menu {
  max-height: 698px !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  opacity: 0;
  transform: translate(-50%, -50%) !important;
  transition: opacity 0.1s ease-in-out !important;
  bottom: auto !important;
  right: auto !important;
}

/*!* When the menu is active (visible) *!*/
#walkme-menu.active {
  opacity: 1;
}

#walkme-overlay {
  opacity: 0;
  transition: opacity 0.1s ease-in-out !important;
}

#walkme-overlay.active {
  opacity: 70% !important;
}

#walkme-player {
  transform: translate(-50%, -50%) !important;
  transition: transform 0.1s linear !important;
}
